<template>
  <div>

    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>日志管理</el-breadcrumb-item>
      <el-breadcrumb-item>登录日志</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-table :data="logloginlist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="登陆用户" prop="ll_mid"></el-table-column>
        <el-table-column label="类型" prop="ll_type"></el-table-column>
        <el-table-column label="登陆退出IP" prop="ll_ip"></el-table-column>
        <el-table-column label="登陆退出时间" prop="ll_date"></el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return{
      logloginlist:[],
      querInfo: {
        page: 1,
        limit: 10
      },
      //总数据条数
      total: 0,
    }
  },
  created() {
    this.getLogloginList()
  },
  methods: {
    //获取登录日志
    async getLogloginList(){
      const {data:res} = await this.$http.get('loglogin/getList',
          {params: this.querInfo})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.logloginlist=res.data.data
      this.total=res.data.total
      console.log(this.logloginlist)
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getLogloginList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getLogloginList()
    },
  }
}
</script>
